import React, { Suspense } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RouteList } from './routes/index';
import RequireAuth from './components/RequireAuth/index';
import Spinner from './components/common/Spinner';
import './assests/css/styles.css';
import './assests/custom/index.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense fallback={<Spinner />}>
        <Routes>
          {RouteList.map((route) => (
            <Route
              key={route}
              path={route.path}
              exact
              element={
                route.auth ? (
                  <RequireAuth>
                    <route.element />
                  </RequireAuth>
                ) : (
                  <route.element />
                )
              }
            />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
