/* eslint-disable max-len */

const useUtils = () => {
  const pascalText = (string) => string
    && string.replace(
      /(\w)(\w*)/g,
      (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
    );

  const getOptions = (valueField, labelField, list) => list.map((item) => ({
    value: item[valueField],
    label: item[labelField],
  }));

  const necessity = (ids) => {
    let result = null;
    if (ids === 0) {
      result = 'Optional';
    } else if (ids === 1) {
      result = 'Recommended';
    } else if (ids === 2) {
      result = 'Important';
    }
    return result;
  };
  const groupList = (reqList) => reqList.reduce((r, a) => {
    r[a.user.uuid] = r[a.user.uuid] || [];
    r[a.user.uuid].push(a);
    return r;
  }, Object.create(null));

  const findOption = (id, field, list) => list.find((item) => id === item[field]);

  const flatten = (a) => (Array.isArray(a) ? [].concat(...a.map(flatten)) : a);

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const findItemById = (id, list) => list.find((item) => item.id === id);
  const findItemByIdField = (id, idField, list) => list.find((item) => item[idField] === id);

  const replaceItem = (itemId, newItem, list) => list.map((x) => (x.id === itemId ? newItem : x));

  const getGreeting = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
      return 'Good Morning';
    }
    if (curHr < 18) {
      return 'Good Afternoon';
    }
    return 'Good Evening';
  };

  return {
    flatten,
    getOptions,
    necessity,
    findOption,
    groupList,
    formatBytes,
    pascalText,
    findItemById,
    replaceItem,
    findItemByIdField,
    getGreeting,
  };
};

export default useUtils;
