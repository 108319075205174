import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

export const sideBarDataSlice = createSlice({
  name: 'setUser',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = sideBarDataSlice.actions;

export default sideBarDataSlice.reducer;
