import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';
import AuthHelper from '../../utils/AuthHelper';
import Footer from '../Layout/Footer';
import LeftSideBar from '../Layout/LeftSideBar';

function RequireAuth({ children }) {
  if (!AuthHelper.validToken()) {
    localStorage.clear();
    return <Navigate to="/" />;
  }
  sessionStorage.setItem('sessionUrl', window.location.pathname);
  return (
    <div className="main-content">
      <LeftSideBar />
      <div className="content-area">
        {children}
        <Footer />
      </div>
    </div>
  );
}

export default memo(RequireAuth);
