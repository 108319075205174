/* eslint-disable comma-dangle */

import Imgreport from '../../assests/images/audit.svg';
import ImgHome from '../../assests/images/home.svg';

export const footerList = [
  {
    name: 'License Agreement',
    url: '#'
  },
  {
    name: 'Privacy',
    url: '#'
  },
  {
    name: 'Security',
    url: '#'
  },
  {
    name: 'Give Feedback',
    url: '#'
  }
];
export const sidebar = [
  {
    name: 'Home',
    url: '/dashboard',
    img: ImgHome
  },
  {
    name: 'Approvals',
    url: '/approvals',
    img: Imgreport
  },
  {
    name: 'Subscriptions',
    url: '/subscriptions',
    img: Imgreport
  },
  {
    name: 'User Subscriptions',
    url: '/user-subscriptions',
    img: Imgreport
  },
  {
    name: 'Coupons',
    url: '/coupons',
    img: Imgreport
  },
];
