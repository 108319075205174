import React from 'react';
import { footerList } from './data';

export default function Footer() {
  return (
    <div className="footer">
      <ul>
        {footerList?.map((item) => (
          <li>
            <a href={item?.url}>{item?.name}</a>
          </li>
        ))}
      </ul>
      <p className="text-center mb-2">
        2023 Project ChronWise Inc. All Rights reserved
      </p>
    </div>
  );
}
