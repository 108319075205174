import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Index({ size }) {
  return (
    <div className="d-flex justify-content-center spin">
      <Spinner className="spinner loader" animation="border" role="status" size={size}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
