import React from 'react';

export default function Logo() {
  return (
    <>
      <span className="logo-header">C</span>
      <span className="logo-sub">hron</span>
      <span className="logo-header">W</span>
      <span className="logo-sub">ise</span>
      {' '}
      <span className="logo-header">A</span>
      <span className="logo-sub">dmin</span>

    </>
  );
}
