import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImgArrow from '../../assests/images/arrow-right.svg';
import SidebarMenu from './SidebarMenu';
import { setOpen } from '../../redux/slices/sideBarDataSlice';

export default function LeftSideBar() {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.sidebar) || {};
  return (
    <div className={open ? 'leftside-bar open' : 'leftside-bar'}>
      <div
        className="leftside-collapse d-xl-none d-block shadow-sm"
        id="filter"
      >
        <button type="button" className="mob-menu" onClick={() => { dispatch(setOpen(!open)); }}>
          <div className="mobilemenu">
            <div className="arrow-right">
              <img src={ImgArrow} alt="arrow" width="20" />
            </div>
          </div>
        </button>
      </div>
      <SidebarMenu />
    </div>
  );
}
