import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import defaultAvatar from '../../assests/images/profile_avatar.png';
import useutils from '../../utils/index';
import { sidebar } from './data';
import { setOpen } from '../../redux/slices/sideBarDataSlice';
import Logout from './Logout';
import Logo from '../common/Logo';

export default function SidebarMenu() {
  const { getGreeting } = useutils();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user) || {};
  const hideDrawer = () => {
    dispatch(setOpen(false));
  };

  return (
    <div className="sidebar-menu">
      <div className="logobox">
        <h5 className="fw-bold"><Logo /></h5>
      </div>
      <div className="profile-det">
        <div className="profile-pic me-2">
          <img
            src={user?.imageFile || defaultAvatar}
            alt="profile"
            onError={(e) => {
              e.target.src = defaultAvatar;
            }}
          />
        </div>
        <div className="profile-content">
          <p className="mb-1">
            {getGreeting()}
            ,
          </p>
          <h5 className="mb-0">
            Admin
          </h5>
        </div>
      </div>
      <ul className="list-unstyled listmenus">
        {sidebar?.map((item) => (
          <li className="sidemenu-items">
            <Link
              onClick={() => hideDrawer()}
              to={item.url}
              className={location?.pathname?.includes(item.url) ? 'active' : ''}
            >
              <img src={item?.img} alt="home" />
              <span>{item?.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <Logout />
    </div>
  );
}
