/* eslint-disable comma-dangle */
import { lazy } from 'react';

const routes = [
  {
    path: '/',
    element: lazy(() => import('../pages/login')),
    auth: false
  },
];
export default routes;
