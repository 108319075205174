// ** Routes Imports
import LoginRoutes from './LoginRoutes';
import DashboardRoutes from './DashboardRoutes';
// ** Default Route
const DefaultRoute = '/login';

// ** Merge Routes
const RouteList = [
  ...LoginRoutes,
  ...DashboardRoutes,
];

export { DefaultRoute, RouteList };
